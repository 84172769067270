import "i18n";

import { Snackbar } from "components/snackbar";
import theme from "components/themes";
import { useLocalStorage } from "hooks";
import Post from "pages/post";
import CheckActivationCode from "pages/account/checkActivationCode";
import CheckResetPasswordCode from "pages/account/checkResetPasswordCode";
import ContactUs from "pages/account/contactUs";
import CreateAccount from "pages/account/createAccount";
import ForgotPassword from "pages/account/forgotPassword";
import Home from "pages/home";
import Login from "pages/login";
import Plan from "pages/plan";
import ResetPassword from "pages/account/resetPassword";
import SendEmail from "pages/account/sendEmail";
import Settings from "pages/settings";
import Authorization from "pages/authorization";
import Welcome from "pages/home/welcome";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import PageContainer from "routers/container";
import { useAppDispatch } from "store";
import { loggedIn } from "store/authentication/reducer";

import { Container, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import PrivateRoute from "./privateRouter";
import SelectTenant from "pages/login/selectTenant";
import { useTranslation } from "react-i18next";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const [localStoragetoken] = useLocalStorage("token", "");
  const { i18n } = useTranslation();
  const [languagePageState, setLanguagePageState] = React.useState(0);

  useEffect(() => {

    var lang = localStorage.getItem('language');
    if (!lang) {
      var language = {
        languageCode: 'en-EN',
        languageId: '713d1ee2-dbd4-47b9-98f6-4abc65c67d04'
      };
      localStorage.setItem('language', JSON.stringify(language));
      i18n.changeLanguage(language.languageCode);
    }

    if (localStoragetoken) {
      dispatch(loggedIn());
    }
  }, []);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth={false} disableGutters={true}>
          <Snackbar>
            <Routes>
              <Route exact path="/" element={<PageContainer languagePageState={languagePageState}>
                <Home onLanguagePageChange={(state) => { setLanguagePageState(state) }} />
              </PageContainer>}>

              </Route>
              <Route path="/giris-yap" element={<PageContainer onlyShowLogo>
                <Login />
              </PageContainer>}>
              </Route>
              <Route path="/select-tenant" element={<PageContainer onlyShowLogo><SelectTenant /></PageContainer>} />
              <Route path="/login-aktivasyon" element={<CheckActivationCode />} />
              <Route path="/sifre-sifirlama-kontrol" element={<CheckResetPasswordCode />} />
              <Route path="/sifremi-unuttum" element={<PageContainer onlyShowLogo><ForgotPassword /></PageContainer>} />
              <Route path="/sifre-sifirla" element={<PageContainer onlyShowLogo><ResetPassword /></PageContainer>} />
              <Route path="/hesap-olustur" element={<PageContainer onlyShowLogo><CreateAccount /></PageContainer>} />
              <Route path="/hos-geldin"  element={<PageContainer><Welcome /></PageContainer>} />
              <Route path="/ekip"  element={<PageContainer><Authorization /></PageContainer>} />
              <Route path="/yayin"  element={<PageContainer><Post /></PageContainer>} />
              <Route path="/email-aktivasyon" element={<PageContainer><SendEmail /></PageContainer>} />
              <Route path="/plan"  element={<PageContainer><Plan /></PageContainer>} />
              <Route path="/ayarlar"  element={<PageContainer><Settings /></PageContainer>} />
              <Route path="/bize-yazin"  element={<PageContainer><ContactUs /></PageContainer>} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Snackbar>
        </Container>
      </ThemeProvider>
    </Router>
  );
};

export default App;
