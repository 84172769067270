import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

import Constants from 'utils/constants';
import {getLanguage} from 'utils'
import axios from 'axios';
import { HttpMethod } from 'utils/constants/enums';


export const useCustomHooks = () => {
    const navigate = useNavigate();
    const reduxState = useSelector((state) => state);
    const { t, i18n } = useTranslation('general');
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    const showSuccessMessage = (message: any) => {
        enqueueSnackbar(t(message), {
            variant: 'success',
        });
    };
    const showWarningMessage = (message: any) => {
        enqueueSnackbar(t(message), {
            variant: 'warning',
        });
    };
    const showInfoMessage = (message: any) => {
        enqueueSnackbar(t(message), {
            variant: 'info',
        });
    };
    const showErrorMessage = (message: any) => {
        enqueueSnackbar(t(message), {
            variant: 'error',
        });
    };
    const proxyExecuteForAuth = (url: any, httpMethod: any, parameters: any) => {
        var request = {
            method: httpMethod,
            url: `${Constants.API_URL}${url}`,
            headers: {
                authorization: `Bearer ${parameters.token}`
            },
            data: parameters.body
        };

        parameters.setIsLoading && parameters.setIsLoading(true);
        axios(request)
            .then(({ data }: any) => {
                parameters.setIsLoading && parameters.setIsLoading(false);
                if (data && data.success) {
                    parameters.callback && parameters.callback(data.data);
                }
                else {
                    showErrorMessage(t('Operation failed'));
                    console.log(data.message);
                }
            })
            .catch((e) => {
                parameters.setIsLoading && parameters.setIsLoading(false);
                showErrorMessage(t('Request failed'));
                console.log(e);
            });
    };
    const proxyExecute = async (url: any, httpMethod: any, parameters: any) => {
        var language = getLanguage();

        var request = {
            method: httpMethod,
            url: `${Constants.API_URL}${url}`,
            headers: {
                authorization: parameters.customToken || '',
                'N-AuthorizedTenantId': parameters.authorizedTenantId,
                'N-DefaultLanguageId': language.languageId
            },
            data: parameters.body
        };

        if (request.headers.authorization === '') {
            if (setToken(request) === false) {
                showErrorMessage('Session expired.');
                redirectToLogin(navigate);
                return;
            }
        }

        parameters.setIsLoading && parameters.setIsLoading(true);

        if (parameters.sync === true) {
            try {
                var data = await axios(request);
                handleResponse(data.data);
                return data.data;
            } catch (e) {
                catchException(e);
                return;
            }
        }

        axios(request)
            .then(({ data }: any) => {
                handleResponse(data);
            })
            .catch((e) => {
                catchException(e);
            });

        function handleResponse(data: any) {
            parameters.setIsLoading && parameters.setIsLoading(false);
            if (data && data.success) {
                if (httpMethod !== HttpMethod.Get) {
                    if (parameters.hideSuccessMessage !== true) {
                        showSuccessMessage(t('Operation completed'));
                    }
                }
                parameters.callback && parameters.callback(data.data);
            }
            else {
                showErrorMessage(t('Operation failed'));
                console.log(data.message);
            }
        }

        function catchException(e: any) {
            parameters.setIsLoading && parameters.setIsLoading(false);
            if (e.response.status === 401) {
                showErrorMessage(t('Session expired'));
                redirectToLogin(navigate);
            }
            else {
                showErrorMessage(t('Request failed'));
            }
            console.log(e);
        }
    };
    const setCache = (key: string, value: string) => {
        localStorage.setItem(key, value);
    };
    const getCache = (key: string): string | null => {
        return localStorage.getItem(key);
    };
    
    const isXs = useMediaQuery(theme.breakpoints.down('sm')); // < 600 - Sadece mobil görünümde
    const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md')); //600 - 1024 - Sadece tablet görünümde
    const isMd = useMediaQuery(theme.breakpoints.down('md')); // < 1024 - Hem mobil hem tablet görünümde
    
    const isLg = useMediaQuery(theme.breakpoints.down('lg'));
    const isXl = useMediaQuery(theme.breakpoints.up('xl'));

    return {
        reduxState,
        t,
        i18n,
        showSuccessMessage,
        showWarningMessage,
        showInfoMessage,
        showErrorMessage,
        proxyExecuteForAuth,
        proxyExecute,
        setCache,
        getCache,
        isXs,
        isSm,
        isMd,
        isLg,
        isXl
    };
};

function setToken(request: any) {
    let token;
    if (typeof window !== "undefined") {
        token = window.localStorage.getItem("token");
    }

    if (token) {
        request.headers.authorization = `Bearer ${JSON.parse(token)}`;
        return true;
    }
    return false;
}

function redirectToLogin(navigate: any) {
    navigate("/giris-yap" );
}
