import { useSnackbar } from "components/snackbar";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyGetCheckActivationQuery } from "store/authentication/api";

const CheckActivationCode: React.FC = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["success", "error"]);

  const [checkActivation] = useLazyGetCheckActivationQuery();

  const check = () => {

  };

  useEffect(() => check(), []);

  return null;
};

export default CheckActivationCode;
