import { useSnackbar } from "components/snackbar";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyCheckResetPasswordQuery } from "store/authentication/api";

const CheckResetPasswordCode: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(["error"]);
  const { enqueueSnackbar } = useSnackbar();
  //const queryObject = queryString.parse(location.search);
  const [checkActivation] = useLazyCheckResetPasswordQuery();

  const check = () => {
    // checkActivation(queryObject.code)
    //   .unwrap()
    //   .then(({ success, data }) => {
    //     if (success) {
    //       history.replace({ pathname: "sifre-sifirla", state: data });
    //     } else {
    //       enqueueSnackbar(t("error:resetPassword"));
    //       history.replace({ pathname: "giris-yap" });
    //     }
    //   });
  };

  useEffect(() => check(), []);

  return null;
};

export default CheckResetPasswordCode;
