import React, { useEffect, useState } from "react";
import useStyles from "./useStyles";
// Material Components
import { Button, Grid, IconButton, TextField, Typography, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Material Icons
import { DragHandle as DragHandleIcon, Delete as DeleteIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from 'components/hooks';
// Screens

// Components
import Dialog from "components/dialog";
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";

import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import Loading from "components/loading";

interface Props {
  onClose?: (dialogResponse: any) => void;
  isEditable?: boolean;
  postType: any;
  id: any;
}

const ImprintDefinition: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute, isXs, isMd } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [list, setList] = useState<any>(undefined);

  useEffect(() => {
    if (list) return;
    var id = 0;
    if (props.postType === 'publication') {
      id = props.id;
      proxyExecute(`Publications/${id}/Imprint`, HttpMethod.Get, {
        setIsLoading: undefined,
        callback: (data: any) => {
          if (!data) {
            setList([{ id: 1, key: "", value: "" }]);
          }
          else
            setList(JSON.parse(data));
        }
      });
    }
    else {
      id = props.id;
      proxyExecute(`Issues/${id}/Imprint`, HttpMethod.Get, {
        setIsLoading: undefined,
        callback: (data: any) => {
          if (!data) {
            setList([{ id: 1, key: "", value: "" }]);
          }
          else
            setList(JSON.parse(data));
        }
      });
    }
  });

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
  };


  const handleKeyChange = (id: any, value: any) => {
    const updatedList = list.map((item: any) =>
      item.id === id ? { ...item, key: value } : item
    );
    setList(updatedList);
  };

  const handleValueChange = (id: any, value: any) => {
    const updatedList = list.map((item: any) =>
      item.id === id ? { ...item, value: value } : item
    );
    setList(updatedList);
  };

  const handleDelete = (id: any) => {
    const updatedList = list.filter((item: any) => item.id !== id);
    // if(updatedList.length === 0){
    //   setList([]);
    // }
    // else {
    setList(updatedList);
    //}
  };

  const handleAddRow = () => {
    const newItem = { id: list.length + 1, key: '', value: '' };
    setList([...list, newItem]);
  };

  /*
  className={classes.dialogContent}
    dialogContent: {
    width: 852,
    padding: 30,
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      padding: "20px 16px",
    },
  },
  */

  return (
    <Dialog open closable={true} fullScreen={isMd ? true : false} title={t('Imprint')} onClose={() => {
      props.onClose && props.onClose(undefined);
    }} showAlwaysTopDivider={true}>
      <Grid container direction="column" style={{ padding: 30, width: (!isMd ? '852px' : '100%') }}>
        {isEditable && <Grid style={{
          boxShadow: '0 1px 5px #00000052',
          borderRadius: 8,
          paddingTop: 20,
          paddingBottom: 10
        }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef}>
                  {list && list.map((item: any, index: any) => (
                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                      {(provided) => (
                        <li
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Grid container>
                            <Grid md={1}>
                              <IconButton><DragHandleIcon /></IconButton>
                            </Grid>
                            <Grid md={5} style={{ padding: 5 }}>
                              <TextField
                                fullWidth
                                multiline
                                name="title"
                                variant="standard"
                                label={t('Title')}
                                value={item.key}
                                onChange={(value) => { handleKeyChange(item.id, value.currentTarget.value) }}
                              />
                            </Grid>
                            <Grid md={5} style={{ padding: 5 }}>
                              <TextField
                                fullWidth
                                multiline
                                name="value"
                                variant="standard"
                                label={t('Value')}
                                value={item.value}
                                onChange={(value) => { handleValueChange(item.id, value.currentTarget.value) }}
                              />
                            </Grid>
                            <Grid md={1}>
                              <IconButton onClick={() => { handleDelete(item.id) }}><DeleteIcon /></IconButton>
                            </Grid>
                          </Grid>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
          <Grid
            justifyContent="center"
            alignItems="center"
            container>
            <Button onClick={handleAddRow}>{t('Add Row')}</Button>
          </Grid>

        </Grid>}
        {!isEditable && <Grid style={{
          boxShadow: '0 1px 5px #00000052',
          borderRadius: 8
        }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ margin: '32px' }}
          >
            {list && (list?.length > 1 || (list?.length === 1 && list[0].key !== "")) && list.map((item: any) => (
              <Box
                key={item.id}
                marginTop={2}
                marginBottom={2}
                textAlign="center"
                width="100%"
              >
                <Typography fontWeight="bold" fontSize={14}>
                  {item.key}
                </Typography>
                <Typography fontSize={14} >
                  {item.value}
                </Typography>
              </Box>
            ))}
            {(list?.length === 0 || (list?.length === 1 && list[0].key === "")) &&
              <Box
                textAlign={"center"}
                width="100%"
              >
                <Typography fontSize={14} >
                  {t('Imprint not added.')}
                </Typography>
              </Box>}
              {!list && <Loading></Loading>}
          </Box>
        </Grid>}
        <Grid item>
          {props.isEditable !== false && !isMd && <LoadingButton loading={isLoading} fullWidth size="large" variant="contained" style={{
            marginTop: 20,
            marginBottom: 10
          }}
            onClick={() => {
              if (!isEditable) {
                setIsEditable(true);
                return;
              }
              setIsEditable(false);
              var id = props.id;
              var imprintList = list.length === 0 ? undefined : list;
              if (props.postType === 'publication') {
                proxyExecute(`Publications/${id}/Imprint`, HttpMethod.Put, {
                  body: { data: JSON.stringify(imprintList) },
                  setIsLoading,
                  callback: () => { }
                });
              }
              else {
                proxyExecute(`Issues/${id}/Imprint`, HttpMethod.Put, {
                  body: { data: JSON.stringify(imprintList) },
                  setIsLoading,
                  callback: () => { }
                });
              }
            }}>
            {isEditable ? t('Save') : t('Edit Imprint')}
          </LoadingButton>}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ImprintDefinition;
