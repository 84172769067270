import React, { useState, useCallback, useEffect, useRef } from "react";
import useStyles from "./useStyles";
// Material Components
import { Container, Grid, IconButton, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Material Icons
import { Close as CloseIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from 'components/hooks';
// Screens
import TextBoxContentDefinition from "pages/post/posted/contentManagement/contentDetailDefinition/textBoxContentDefinition";
// Components
import TextBoxContentContainer from "components/textBoxContentContainer";
import Dialog from "components/dialog";
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";

import { TextProvider } from './HtmlContentContext';

import { addImageToContent } from "utils/apiCalls/contents";
import NinovienEditorViewer from "./NinovienEditorViewer";

interface Props {
  onClose: () => void;
  contentId: any;
  isReadonly?: boolean;
  customActions?: any;
}

const initialHtml2 = '<p dir="ltr"><span style="white-space: pre-wrap;">There\'s a whole lot to discover in this editor</span></p>'

const ContentDetailDefinition: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute, showWarningMessage } = useCustomHooks();
  const classes = useStyles();
  const childRef = useRef(null);

  // Variables
  const [imageListWithBase64, setImageListWithBase64] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastImageCount, setLastImageCount] = useState(0);
  const [content, setContent] = useState<any>();
  const [htmlContent, setHtmlContent] = useState<any>();
  const [contentDetail, setContentDetail] = useState<any>();
  const [openTextBoxContentDefinitionAsDialog, setOpenTextBoxContentDefinitionAsDialog] = useState<boolean>(false);
  const [textboxContent, setTextboxContent] = useState<any>();

  const getJsonObject = (contentDetail: any) => {
    try {
      return JSON.parse(contentDetail);
    } catch (e) {
      return contentDetail;
    }
  }

  useEffect(() => {
    if (content) return;
    localStorage.removeItem('blobUrlList');
    var id = props.contentId;
    proxyExecute(`Contents/WithDetail/${id}`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => {
        setContent(data);
        setContentDetail(getJsonObject(data.detail));
        setHtmlContent(data.detail);
      }
    });
  });

  const handleImageChange = useCallback(async (blobUrl: any, imageName: any, index: any, imageList: any) => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();

    const reader = new FileReader();

    reader.onload = async () => {
      const base64String = reader?.result;

      const image = new Image();
      image.onload = async () => {
        const maxWidth = 1800;
        const maxHeight = 4000;

        let width = image.width;
        let height = image.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx?.drawImage(image, 0, 0, width, height);

        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7);

        var image2 = imageList.find((x: any) => x.blobUrl === blobUrl);
        image2.base64Data = compressedBase64;
        image2.imageUrl = await addImageToContent(props.contentId, blobUrl, imageName, compressedBase64);
        // var id = props.contentId;
        // var data:any = await proxyExecute(`Contents/${id}/AddImage`, HttpMethod.Put, {
        //   body: {
        //     blobUrl: blobUrl,
        //     imageName: imageName,
        //     base64Data: compressedBase64
        //   },
        //   setIsLoading: undefined,
        //   sync: true
        // });
        //image2.imageUrl = data.imageUrl;
        setImageListWithBase64(imageList);
        var blobUrlListString = localStorage.getItem('blobUrlList');
        var blobUrlList = JSON.parse(blobUrlListString || '[]');
        var blobImage = blobUrlList.find((x: any) => x.blobUrl === blobUrl);
        blobImage.imageUrl = image2.imageUrl;
        localStorage.setItem('blobUrlList', JSON.stringify(blobUrlList));
        setIsLoading(false);
      };

      image.src = "" + base64String;
    };

    reader.readAsDataURL(blob);
  }, [imageListWithBase64, isLoading]);

  const getRightHeader = () => {
    return (
      <Grid container justifyContent="center" spacing={1}>
        <Grid item>
          {!props.isReadonly && content.contentTypeId !== 'c3ef221b-5672-44ca-94c7-b04301435512' &&
            <LoadingButton size="large" variant="contained" onClick={() => {
              saveContent();
            }} loading={isLoading}>
              {t('Save')}
            </LoadingButton>}
          {props.customActions}
        </Grid>
      </Grid>
    );
  }

  const saveContent = () => {
    var contentJson = getJsonObject(contentDetail);
    //callSaveService(props, contentJson, imageListWithBase64);
    triggerSaveAction();
  }

  const closeIcon = () => {
    return (
      <IconButton color="inherit" onClick={props.onClose} className={classes.closeIcon}>
        {props.customActions ? <CloseIcon /> : <ArrowBackIcon />}
      </IconButton>
    );
  };

  if (!content) return null;

  const callSaveService2 = async (props: Props, htmlContent: any, imageListWithBase64: any[]) => {
    var imageList = getSrcList(htmlContent);

    // imageList.map((x:any)=>{
    //  saveImageOfContent(x,x.split('/').pop(),1,[]);
    // })

    if (imageList.length !== lastImageCount) {
      setLastImageCount(imageList.length);

      imageList.map((blobUrl: any, index: any) => {
          var blobUrlListString = localStorage.getItem('blobUrlList');
          var blobUrlList = JSON.parse(blobUrlListString || '[]');
          var itemExist = blobUrlList.find((x: any) => x.blobUrl === blobUrl)
          if (!itemExist) {
            var image = imageListWithBase64.find((x: any) => x.blobUrl == blobUrl);
            if (!image) {
              var newList = [...imageListWithBase64, {
                index: index,
                blobUrl: blobUrl,
                imageUrl: undefined,
                imageName: blobUrl.split('/').pop()+ '.jpeg'
              }];
              setImageListWithBase64(newList);
              blobUrlList.push({
                index: index,
                blobUrl: blobUrl,
                imageUrl: undefined,
                imageName: blobUrl.split('/').pop()+ '.jpeg'
              });
              localStorage.setItem('blobUrlList', JSON.stringify(blobUrlList));
              handleImageChange(blobUrl, blobUrl.split('/').pop()  + '.jpeg', index, newList);
            }
          }
      });
    }


    const timeout = setTimeout(() => {

      clearTimeout(timeout);

      var blobUrlListString = localStorage.getItem('blobUrlList');
      var blobUrlList = JSON.parse(blobUrlListString || '[]');
      blobUrlList.map((image: any) => {
        htmlContent = htmlContent.replace(image.blobUrl, image.imageUrl);
      });
  
      var newImageList = getSrcList(htmlContent);
      var imageGuidList: any[] = [];
      //var imageList = JSON.parse(contentJsonString).content.filter((x: any) => x.type === 'ImageBlock');
      if (newImageList.length > 0) {
        newImageList.map((bUrl: any, index: any) => {
          imageGuidList.push(bUrl.split('/').pop().split('.')[0]);
        });
      }
  
      var id = props.contentId;
      proxyExecute(`Contents/Detail/${id}`, HttpMethod.Put, {
        body: { detail: htmlContent, imageGuidList },
        setIsLoading,
        callback: () => { 
          
        }
      });

    }, 5000);
  }

  const saveImageOfContent = async (blobUrl: any, imageName: any, index: any, imageList: any) => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();

    const reader = new FileReader();

    reader.onload = async () => {
      const base64String = reader?.result;

      const image = new Image();
      image.onload = async () => {
        const maxWidth = 1800;
        const maxHeight = 4000;

        let width = image.width;
        let height = image.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx?.drawImage(image, 0, 0, width, height);

        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7);

        var image2 = imageList.find((x: any) => x.blobUrl === blobUrl);
        image2.base64Data = compressedBase64;
        image2.imageUrl = await addImageToContent(props.contentId, blobUrl, imageName, compressedBase64);
        // var id = props.contentId;
        // var data:any = await proxyExecute(`Contents/${id}/AddImage`, HttpMethod.Put, {
        //   body: {
        //     blobUrl: blobUrl,
        //     imageName: imageName,
        //     base64Data: compressedBase64
        //   },
        //   setIsLoading: undefined,
        //   sync: true
        // });
        //image2.imageUrl = data.imageUrl;
        setImageListWithBase64(imageList);
        var blobUrlListString = localStorage.getItem('blobUrlList');
        var blobUrlList = JSON.parse(blobUrlListString || '[]');
        var blobImage = blobUrlList.find((x: any) => x.blobUrl === blobUrl);
        blobImage.imageUrl = image2.imageUrl;
        localStorage.setItem('blobUrlList', JSON.stringify(blobUrlList));
        setIsLoading(false);
      };

      image.src = "" + base64String;
    }

    reader.readAsDataURL(blob);
  };

  const getSrcList = (html: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const imgElements = doc.querySelectorAll('img');
    const srcList = Array.from(imgElements).map(img => img.src);
    return srcList;
  }

  const callSaveService = (props: Props, contentJson: any, imageListWithBase64: any[]) => {
    var contentJsonString = JSON.stringify(contentJson);

    const hasUnloadedImage = imageListWithBase64.some(x => x.imageUrl == '' || x.imageUrl === undefined);
    if (hasUnloadedImage) {
      showWarningMessage('Upload image is in progress');
      return;
    }

    // imageListWithBase64.map((image) => {
    //   contentJSON = contentJSON.replace(image.blobUrl, image.imageUrl);
    // });

    var blobUrlListString = localStorage.getItem('blobUrlList');
    var blobUrlList = JSON.parse(blobUrlListString || '[]');
    blobUrlList.map((image: any) => {
      contentJsonString = contentJsonString.replace(image.blobUrl, image.imageUrl);
    });

    var imageGuidList: any[] = [];
    var imageList = JSON.parse(contentJsonString).content.filter((x: any) => x.type === 'ImageBlock');
    if (imageList.length > 0) {
      imageList.map((cont: any, index: any) => {
        imageGuidList.push(cont.attrs.url.split('/').pop().split('.')[0]);
      });
    }

    var id = props.contentId;
    proxyExecute(`Contents/Detail/${id}`, HttpMethod.Put, {
      body: { detail: contentJsonString, imageGuidList },
      setIsLoading,
      callback: () => { }
    });
  }

  const handleSaveAction = (html) => {
    callSaveService2(props, html, imageListWithBase64);
  }

  const triggerSaveAction = () => {
    if (childRef.current && childRef.current.triggerGetHtml) {
      childRef.current.triggerGetHtml();
    } else {
      console.log("Child component or method not available yet.");
      alert("Child component is not ready yet!");
    }
  };

  return (
    <Dialog
      open
      fullScreen={!props.customActions}
      closable={false}
      titleTextAlign="left"
      onClose={props.onClose}
      leftHeader={closeIcon()}
      rightHeader={getRightHeader()}
      showAlwaysTopDivider={true}
      title={content.title}
    >
      <Container
        maxWidth="md"
        disableGutters
        classes={{
          maxWidthMd: classes.maxWidthMd,
        }}
        style={{ padding: '32px' }}
      >
        {content.contentChapter &&
          content.contentChapter.code !== 'header-chapter' &&
          <Typography style={{
            marginBottom: '10px', fontSize: '16px', fontWeight: 700,
            color: "#4F525B"
          }}>{content.contentChapter.name.toLocaleUpperCase('tr-TR')}</Typography>}

        <Typography style={{ fontSize: '44px', lineHeight: '50px', fontWeight: '800', marginLeft: '-2px' }}>{content.title}</Typography>

        {content.showWriter &&
          content.writers &&
          content.writers.length > 0 &&
          <Typography variant="body1" style={{ marginTop: '10px' }}>
            {content.writers.map((writer: any, index: number) => {
              return writer.name + (index === content.writers.length - 1 ? '' : ', ')
            })}
          </Typography>
        }
        <Grid container style={{ marginTop: '50px' }}>
          {content.contentTypeId !== 'c3ef221b-5672-44ca-94c7-b04301435512' && <Grid item xs={12}>
            <TextProvider>
              <NinovienEditorViewer isReadonly={props.isReadonly} htmlContent={htmlContent} ref={childRef} setHtmlToParent={handleSaveAction}></NinovienEditorViewer>
            </TextProvider>
          </Grid>}
          {content.contentTypeId === 'c3ef221b-5672-44ca-94c7-b04301435512' && <Grid item xs={12}>
            {!props.isReadonly &&
              <LoadingButton size="large" variant="contained" onClick={() => {
                setTextboxContent(undefined);
                setOpenTextBoxContentDefinitionAsDialog(true);
              }} loading={isLoading}>
                {t('Add Text Box')}
              </LoadingButton>}

            <Grid style={{ marginRight: -16 }}>
              <TextBoxContentContainer isReadOnly={props.isReadonly} contentDetail={contentDetail} onDeleteClick={(id: any) => {

                var newContentDetail = contentDetail.filter((item: any) => item.id !== id);
                setContentDetail(newContentDetail);

                var id = props.contentId;
                proxyExecute(`Contents/Detail/${id}`, HttpMethod.Put, {
                  body: { detail: JSON.stringify(newContentDetail) },
                  setIsLoading,
                  callback: () => { }
                });
              }}
                onEditClick={(id: any) => {
                  var textboxContentData = contentDetail.find((item: any) => item.id === id);
                  setTextboxContent(textboxContentData);
                  setOpenTextBoxContentDefinitionAsDialog(true);
                }}></TextBoxContentContainer>
            </Grid>
          </Grid>}
          <Grid container justifyContent="center" spacing={1}>
          </Grid>
        </Grid>
      </Container>
      {openTextBoxContentDefinitionAsDialog &&
        <TextBoxContentDefinition contentId={props.contentId} textboxContent={textboxContent}
          onClose={(data) => {
            setOpenTextBoxContentDefinitionAsDialog(false);

            if (!data) return;
            var newContentDetail;
            if (textboxContent) {
              newContentDetail = contentDetail.map((item: any) => {
                if (item.id === data.id) {
                  return data;
                }
                return item;
              });
              setTextboxContent(undefined);
            }
            else {
              newContentDetail = contentDetail;
              if (!newContentDetail)
                newContentDetail = [];
              newContentDetail.push(data);
            }

            setContentDetail(newContentDetail);

            var id = props.contentId;
            proxyExecute(`Contents/Detail/${id}`, HttpMethod.Put, {
              body: { detail: JSON.stringify(newContentDetail) },
              setIsLoading,
              callback: () => { }
            });
          }}></TextBoxContentDefinition>}
    </Dialog>
  );
};

export default ContentDetailDefinition;


