import React, { createContext, useState, useContext } from 'react';

// Create a Context for the text
const TextContext = createContext();

// Provider Component to manage the state of text
export const TextProvider = ({ children }) => {
    const [text, setText] = useState('');

    return (
        <TextContext.Provider value={{ text, setText }}>
            {children}
        </TextContext.Provider>
    );
};

// Custom Hook to use the TextContext
export const useText = () => useContext(TextContext);
