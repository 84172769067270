import { useLocalStorage } from "hooks";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store";
import { useGetUserInfoQuery, useLazyGetLogoutQuery } from "store/authentication/api";
import { loggedOut } from "store/authentication/reducer";
import { getLanguage, getUserInfo } from "../../utils";
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// Custom Hooks
import { useCustomHooks } from 'components/hooks';

enum UserInfoMenuType {
  LoggedOut,
}

const User: React.FC = () => {
  const { t } = useCustomHooks();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data } = useGetUserInfoQuery();
  const [logoutApi] = useLazyGetLogoutQuery();
  const [, setLocalStorageToken] = useLocalStorage("token", "");
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { i18n } = useTranslation();

  const settings = [
    {
      name: t('Log out'),
      id: UserInfoMenuType.LoggedOut,
    },
  ];

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickProfileMenuButton = (id: UserInfoMenuType) => {
    if (id === UserInfoMenuType.LoggedOut) {
      logoutApi()
        .unwrap()
        .then(({ success }) => {
          if (success) {
            dispatch(loggedOut());
            setLocalStorageToken("");
            navigate("/giris-yap");
          }
        });
    }
    handleCloseUserMenu();
  };

  return (
    <Box>
      <div>
        <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
          <Avatar alt={getUserInfo()?.email} sx={{ bgcolor: "primary.main" }}>
            {getUserInfo()?.email?.slice(0, 1)}
          </Avatar>
        </IconButton>
      </div>

      <Menu
        keepMounted
        sx={{ mt: "45px" }}
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem key={6}>
          <Typography textAlign="center">{getUserInfo()?.email}</Typography>
        </MenuItem>
        {settings.map((setting) => (
          <MenuItem key={setting.id} onClick={() => handleClickProfileMenuButton(setting.id)}>
            <Typography textAlign="center">{setting.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default User;
