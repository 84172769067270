import { useFormik } from "formik";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store";
import { usePostRegisterMutation } from "store/authentication/api";
import { userInfo } from "store/authentication/reducer";
import * as Yup from "yup";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { LoadingButton } from "@mui/lab";
import { Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Link, Paper, TextField, Typography } from "@mui/material";

import useStyles from "./useStyles";
import ContentDetailDefinition from "pages/post/posted/contentManagement/contentDetailDefinition";

const CreateAccount: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["createAccount", "error"]);
  const [register, { isLoading }] = usePostRegisterMutation();
  const [isContractChecked, setContractChecked] = useState<boolean>(false);
  const [passwordInputType, setPasswordInputType] = useState<"text" | "password">("password");
  const [rePasswordInputType, setRePasswordInputType] = useState<"text" | "password">("password");
  const [openContentDetailDefinitionAsDialog, setOpenContentDetailDefinitionAsDialog] = useState<any>();
  const [contractContentId, setContractContentId] = useState<any>();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      lastname: "",
      password: "",
      firstname: "",
      rePassword: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("error:required")),
      firstname: Yup.string().required(t("error:required")),
      lastname: Yup.string().required(t("error:required")),
      email: Yup.string().email(t("error:email")).required(t("error:required")),
      password: Yup.string()
        .required(t("error:required"))
        .matches(/^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[aA-zZ]){1}).*$/, t("error:passwordMatches")),
      rePassword: Yup.string()
        .required(t("error:required"))
        .oneOf([Yup.ref("password"), null], t("error:passwordMustMatch")),
    }),
    onSubmit: (values) => {
      register({
        name: values.name,
        email: values.email,
        lastname: values.lastname,
        password: values.password,
        firstname: values.firstname,
        confirmPassword: values.rePassword,
      })
        .unwrap()
        .then(({ success }) => {
          if (success) {
            dispatch(
              userInfo({
                email: values.email,
                lastname: values.lastname,
                firstname: values.firstname,
              })
            );
            navigate("/email-aktivasyon");
          }
        });
    },
  });

  const handleGoLogin = () => {
    navigate("/giris-yap");
  };

  return (
    <Grid container direction="column" alignItems="center" alignContent="center" className={classes.createAccountContainer}>
      <form onSubmit={formik.handleSubmit}>
        <Grid item>
          <Paper className={classes.paper}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.title}>{t("createAccount")}</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  name="firstname"
                  label={t("name")}
                  variant="standard"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  helperText={formik.touched.firstname && formik.errors.firstname}
                  error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  name="lastname"
                  variant="standard"
                  label={t("surname")}
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  helperText={formik.touched.lastname && formik.errors.lastname}
                  error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  name="name"
                  label={t("Account name")}
                  variant="standard"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  helperText={formik.touched.name && formik.errors.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  name="email"
                  label={t("email")}
                  variant="standard"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  helperText={formik.touched.email && formik.errors.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  name="password"
                  variant="standard"
                  label={t("password")}
                  type={passwordInputType}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  helperText={formik.touched.password && formik.errors.password}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className={classes.adornment} position="end">
                        <IconButton
                          color="inherit"
                          className={classes.passwordInfoButton}
                          onClick={() => {
                            setPasswordInputType(passwordInputType === "password" ? "text" : "password");
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  name="rePassword"
                  variant="standard"
                  label={t("rePassword")}
                  type={rePasswordInputType}
                  onChange={formik.handleChange}
                  value={formik.values.rePassword}
                  helperText={formik.touched.rePassword && formik.errors.rePassword}
                  error={formik.touched.rePassword && Boolean(formik.errors.rePassword)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className={classes.adornment} position="end">
                        <IconButton
                          color="inherit"
                          className={classes.passwordInfoButton}
                          onClick={() => {
                            setRePasswordInputType(rePasswordInputType === "password" ? "text" : "password");
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography className={classes.passwordInfo}>{t("passwordInfo")}</Typography>
              </Grid>
              <Grid container alignItems="center" xs={12} style={{ padding: '16px 0 0 16px' }}>
                <Grid item xs={1}>
                  <FormControlLabel
                    control={<Checkbox checked={isContractChecked} onChange={(event, value) => { setContractChecked(value); }} />}
                    label=""
                  />
                </Grid>
                <Grid item xs={11}>
                  <Typography className={classes.contractInfo}>

                    <Link style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setContractContentId('c8e57e46-d6a9-4cc7-acfd-b16000bea4d2');
                        setOpenContentDetailDefinitionAsDialog(true);
                      }}>Kullanıcı Sözleşmesi </Link>

                    ve

                    <Link style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setContractContentId('c8e57e46-d6a9-4cc7-acfd-b16000bea4d2');
                        setOpenContentDetailDefinitionAsDialog(true);
                      }}> Gizlilik İlkeleri </Link>

                    ’ni kabul ediyorum.

                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  disabled={!isContractChecked}
                  className={classes.createAccountButton}
                >
                  {t("next")}
                </LoadingButton>
              </Grid>
              <Grid item>
                <Typography className={classes.signInInfo}>
                  {t("hasAccount")}{" "}
                  {
                    <Button variant="outlined" onClick={handleGoLogin} className={classes.signInButton}>
                      {t("login")}
                    </Button>
                  }
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          {openContentDetailDefinitionAsDialog &&
            <ContentDetailDefinition
              isReadonly={true}
              contentId={contractContentId}
              onClose={() => {
                setOpenContentDetailDefinitionAsDialog(false);
              }}
              // customActions={<LoadingButton size="large" variant="contained" onClick={() => {
              // }}>
              //   {t('Subscribe')}
              // </LoadingButton>}
              customActions={<div></div>}
            />}
        </Grid>
      </form>
    </Grid>
  );
};

export default CreateAccount;
