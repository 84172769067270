import React, { useState, useRef, useEffect } from "react";
import useStyles from "./useStyles";
// Material Components
import { Button, Divider, Grid, IconButton, Typography, ButtonGroup, Popper, ClickAwayListener, Paper, Grow, MenuItem, MenuList } from "@mui/material";
// Material Icons
import { DragHandle as DragHandleIcon, MoreVert as MoreVertIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from 'components/hooks';
// Screens
import ContentDefinition from "../../contentDefinition";
import ChapterDefinition from "../../chapterDefinition";
// Components
import AddContentChapterButton from "../../addContentChapterButton";
import DeleteConfirmation from "components/deleteConfirmation";
import Content from "../content";
// Constants&Enums
import { ChapterType, HttpMethod } from "utils/constants/enums";

import clsx from "clsx";
import { Draggable, DraggableProvidedDragHandleProps, Droppable } from '@hello-pangea/dnd';
import { getAnswerListStyle, getItemStyle } from "../utils";

interface Props {
  chapterIndex: number;
  chapterId: any;
  dragProps?: DraggableProvidedDragHandleProps;
  reloadChapters?: () => void;
  chapterType: any;
  hasBody: any;
  postType: any;
  postId: any;
  isLastBodyChapter: any;
  tenantId: any;
  authorizationContext: any;
  chapter:any;
}

const Chapter: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute, isMd } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [openAddContentAsPopper, setOpenAddContentAsPopper] = useState<boolean>(false);
  const [isHover, setHover] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [chapter, setChapter] = useState<any>();
  const [openChapterDefinitionasDialog, setOpenChapterDefinitionasDialog] = useState<boolean>(false);
  const [deleteChapterConfirmation, setDeleteChapterConfirmation] = useState(false);

  useEffect(() => {
    if (chapter) return;

    if(props.chapter)
      setChapter(props.chapter);

    var id = props.chapterId;
    // proxyExecute(`ContentChapters/${id}`, HttpMethod.Get, {
    //   setIsLoading: undefined,
    //   callback: (data: any) => { setChapter(data) },
    //   authorizedTenantId: props.tenantId
    // })

  }, [chapter]);

  const isHeader = props.chapterType === ChapterType.Header;
  const isFooter = props.chapterType === ChapterType.Footer;
  const isBody = props.chapterType === ChapterType.Body;

  const handleToggle = (value: any) => {
    if (value === undefined)
      setOpenContextMenu((prevOpenContextMenu) => !prevOpenContextMenu);
    else
      setOpenContextMenu(value);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenContextMenu(false);
  };

  if (!chapter)
    return (<></>);

  return (
    <Grid
      container
      className={clsx({
        [classes.header]: isHeader && props.hasBody,
      })}
    >
      {isFooter && props.hasBody && (
        <Grid item xs={12} className={isMd ? classes.paddingRight : classes.paddingRight}>
          <Divider />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid
          container
          spacing={1}
          className={clsx({
            [classes.footer]: isFooter && props.hasBody,
            [classes.chapterContainer]: props.hasBody,
          })}
        >
          {isBody && (
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <Grid item className={classes.chapterTitleTopContainer}>
                  <Grid container alignItems="center" justifyContent="space-between" className={classes.chapterTitleTopWrapper}>
                    <Grid item>
                      <Typography variant="overline" className={classes.chapterTitle}>{chapter?.title.toLocaleUpperCase('tr-TR')}</Typography>
                    </Grid>
                    {(!props.tenantId || (props.authorizationContext.hasAccessToDeleteChapter && props.authorizationContext.hasAccessToEditChapter)) && <Grid item>
                      <>
                        <ButtonGroup ref={anchorRef}  >
                          <IconButton onClick={handleToggle}>
                            {isHover && !isMd && <MoreVertIcon style={{ height: '44px' }} />}
                          </IconButton>
                        </ButtonGroup>
                        <Popper style={{ zIndex: 2 }} transition open={openContextMenu} disablePortal role={undefined} anchorEl={anchorRef.current}
                          placement={'bottom-end'}>
                          {({ TransitionProps }) => (
                            <Grow {...TransitionProps}>
                              <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                  <MenuList id="split-button-menu">
                                    <MenuItem onClick={() => {
                                      setOpenContextMenu(false);
                                      setDeleteChapterConfirmation(true);
                                    }}>
                                      {t('Delete')}
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                      setOpenChapterDefinitionasDialog(true);
                                    }}>{t('Edit')}</MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </>
                    </Grid>}
                  </Grid>
                </Grid>
                {(!props.tenantId || props.authorizationContext.hasAccessToReorderContent) && <Grid item>
                  <IconButton {...props.dragProps}>{isHover && !isMd && <DragHandleIcon />}</IconButton>
                </Grid>}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
          <Droppable droppableId={`droppableVM$${props.chapterId}`} type="content">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={getAnswerListStyle(snapshot.isDraggingOver)}>
                  {chapter.contents.map((contentId: any, index: number) => {
                    return (
                      <Draggable index={index} key={`${props.chapterIndex}${index}`} draggableId={`${props.chapterIndex}${index}`}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <div {...provided.dragHandleProps} />
                            <Content content={chapter.contentList.find((x:any)=>x.id == contentId)} tenantId={props.tenantId} authorizationContext={props.authorizationContext} contentId={contentId} dragProps={provided.dragHandleProps}
                              reloadContents={() => {
                                setChapter(undefined);
                              }}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Grid>
          {!openAddContentAsPopper && (!props.tenantId || (props.authorizationContext.hasAccessToAddChapter && props.authorizationContext.hasAccessToAddContent)) && !isMd && (
            <Grid container justifyContent="space-between" alignItems="center">
              {isBody && (
                <Grid item className={classes.paddingRight}>
                  <Grid container justifyContent="space-between">
                    <Grid item style={{ paddingLeft: 8 }}>
                      <Button variant="contained" onClick={() => setOpenAddContentAsPopper(true)}>
                        {t('Add Content to Chapter')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {(isHeader && props.hasBody) && (
                <>
                  {props.hasBody && <Grid item xs={12} className={classes.paddingRight}>
                    <Button variant="text" onClick={() => setOpenAddContentAsPopper(true)} className={classes.addContentButton}>
                      {t('Add content before chapters')}
                    </Button>
                  </Grid>}
                </>
              )}
              {(isFooter && props.hasBody) && (
                <Grid item xs={12} className={classes.paddingRight}>
                  <Button variant="text" onClick={() => setOpenAddContentAsPopper(true)} className={classes.addContentButton}>
                    {t('Add content after chapters')}
                  </Button>
                </Grid>
              )}
              {(!props.hasBody && isHeader) && <Grid item xs={12}>
                <AddContentChapterButton reloadChapters={() => {
                  props.reloadChapters && props.reloadChapters();
                }} postType={props.postType} id={props.postId} chapterId={props.chapterId} />
              </Grid>}
              {(props.hasBody && props.isLastBodyChapter) && <Grid item style={{ marginRight: 36 }}>
                <AddContentChapterButton reloadChapters={() => {
                  props.reloadChapters && props.reloadChapters();
                }} postType={props.postType} id={props.postId} chapterId={props.chapterId} showOnlyAddChapterButton={true} />
              </Grid>}
            </Grid>
          )}
          {openAddContentAsPopper && (
            <Grid item xs={12}>
              <ContentDefinition chapterId={props.chapterId} onClose={(result: any) => {
                setOpenAddContentAsPopper(false);
                if (result) {
                  setChapter(undefined);
                }
              }} />
            </Grid>
          )}
        </Grid>
      </Grid>
      {isHeader && props.hasBody && (
        <Grid item xs={12} className={ isMd ? classes.paddingRightMd : classes.paddingRight}>
          <Divider />
        </Grid>
      )}
      {openChapterDefinitionasDialog && <ChapterDefinition
        chapterId={props.chapterId}
        onClose={() => {
          setOpenChapterDefinitionasDialog(false);
          props.reloadChapters && props.reloadChapters();
        }} ></ChapterDefinition>}
      {deleteChapterConfirmation && <DeleteConfirmation onDeleteClicked={() => {
        setDeleteChapterConfirmation(false);
        var id = props.chapterId;
        proxyExecute(`ContentChapters/${id}`, HttpMethod.Delete, {
          setIsLoading: undefined,
          callback: () => { props.reloadChapters && props.reloadChapters() }
        })
      }}
        onCancelClicked={() => {
          setDeleteChapterConfirmation(false);
        }}></DeleteConfirmation>}
    </Grid>
  );
};

export default Chapter;
